modules.define('helpers', function() {
    return {
        setVendors: function(property, value) {
            var style = "";

            style += "-webkit-" + property + ": " + value + "; ";
            style += "-moz-" + property + ": " + value + "; ";
            style += "-ms-" + property + ": " + value + "; ";
            style += "-o-" + property + ": " + value + "; ";
            style += property + ": " + value + ";";

            return style;
        },
        debounce: function(func, wait, immediate) {
            var timeout;

            return function() {
                var context = this,
                        args = arguments,
                        callNow = immediate && !timeout;

                var later = function() {
                    timeout = null;
                    if (!immediate)
                        func.apply(context, args);
                }

                clearTimeout(timeout);
                timeout = setTimeout(later, wait);

                if (callNow)
                    func.apply(context, args);
            }
        },
        viewport: function() {
            var e = window,
                    a = 'inner';

            if (!('innerWidth' in window)) {
                a = 'client';
                e = document.documentElement || document.body;
            }

            return {width: e[ a + 'Width' ], height: e[ a + 'Height' ]};
        },
        ajax: function(options) {
            var defaults = {
                type: "POST",
                dataType: "json",
                error: function(jqXHR, textStatus, errorThrown) {
                    // Ajax Fail state handler
                    ajaxFailHandler.showFailPanel();
                }
            };

            var ajaxOptions = $.extend(true, {}, defaults, options);

            return $.ajax(ajaxOptions);
        },
        getHashValue: function(key) {
            var hash = location.hash.match(new RegExp(key + '=([^&]*)'));

            if (location.hash && hash) {
                return hash[1];
            }

            return null;
        },
        /**
         * Checks is it element in viewport
         * @param  { HTML or Jquery object }  	el 		Element to check
         * @return { Boolean }							Return true if element is in viewport, false otherwise
         */
        isElementInViewport: function(el) {
            if (typeof jQuery === "function" && el instanceof jQuery) {
                el = el[0];
            }

            if (el) {
                var rect = el.getBoundingClientRect();

                return (
                        rect.top >= 0 &&
                        rect.left >= 0 &&
                        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
                        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
                        );
            }
        },
        /**
         * Founction for checking does device has touch screen
         * @return { Boolean } 		True/false
         */
        isTouchDevice: function() {
            return (('ontouchstart' in window) || (navigator.MaxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0));
        },
        /**
         * Does it browser support css transforms
         * @return { Boolean } 		True/false
         */
        supportTransform: function() {
            var div = document.createElement("div"),
                    divStyle = div.style,
                    suffix = "Transform",
                    testProperties = [
                "O" + suffix,
                "ms" + suffix,
                "Webkit" + suffix,
                "Moz" + suffix
            ],
                    i = testProperties.length;

            while (i--) {
                if (testProperties[i] in divStyle) {
                    return true;
                }
            }

            return false;
        },
        lastScrollTop: 0,
        /**
         * Returns scroll direction, works with Jquery scroll event
         * @param  { Jquery object } 	$window 	Reference on window object inside scroll event
         * @return { String }         				'up' or 'down'
         */
        getScrollDirection: function($window) {
            var current = $window.scrollTop();

            if (current > this.lastScrollTop) {
                this.lastScrollTop = current;
                return 'up';
            } else {
                this.lastScrollTop = current;
                return 'down';
            }
        }
    }
});
