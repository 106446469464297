var modules = (function() {

    var implicitNamespaces = {};

    /*
     * Check if the given name has been provided. This will return false for
     * names that are available only as implicit namespaces.
     */
    function isProvided(name) {
        return !implicitNamespaces[name] && isDefAndNotNull(getObjectByName(name));
    }

    /*
     * Returns true if the specified value is defined and not null.
     */
    function isDefAndNotNull(val) {
        return (typeof val !== "undefined" && val !== null);
    }

    /*
     * Returns an object based on its fully qualified external name. The object
     * is not found if null or undefined.
     */
    function getObjectByName(name) {
        var parts = name.split("."),
            partCount = parts.length;
        var cursor = window;
        for (var i = 0; i < partCount; i++) {
            var part = parts[i];
            if (isDefAndNotNull(cursor[part]))
            	cursor = cursor[part];
            else
            	return null;
        }

        return cursor;
    }

    /*
     * Creates object stubs for a namespace. Taken from
     * https://code.google.com/p/closure-library/source/browse/closure/goog/base.js
     */
    function provide(name) {
        // Ensure that the same namespace isn't provided twice
        if (isProvided(name))
        	throw Error("Namespace " + name + " already declared.");

        delete implicitNamespaces[name];

        var namespace = name;
        while ((namespace = namespace.substring(0, namespace.lastIndexOf('.')))) {
            if (getObjectByName(namespace))
            	break;
            
            implicitNamespaces[namespace] = true;
        }

        var cursor = window;

        var parts = name.split(".");

        for (var part; parts.length && (part = parts.shift());) {
            if (cursor[part])
            	cursor = cursor[part];
            else
            	cursor = cursor[part] = {};
        }

        return cursor;
    }

    return {
        /*
         * Define a module. Module is a well-scoped object that avoids polluting
         * the global namespace. 
         * Additional arguments will be passed to the module. 
         * If the module has an init function, it will be called automatic
         * 
         * @param {string} name - The name of the module (namespace, e.g. charts or ui.charts.basic). 
         * @param {object} module - A function that returns a struct (object).
         */
        define: function(name, module) {
            var namespace = provide(name);
            $.extend(true, namespace, module.apply(namespace, Array.prototype.slice.call(arguments, 2)));

            if (namespace.hasOwnProperty("init") && typeof namespace.init == "function") {
                namespace.init();
            }
        }

    };

}());
