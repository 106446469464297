var $header = $('.fixed-nav'),
        $baseHeaders = $("main .column .column-headline, #right-side .column-headline");
footerHeight = 68,
        colPaddingTop = 82,
        mainHeight = $('main').height();

$('main > .column').height(mainHeight - footerHeight);
$('aside').height(mainHeight);
$('#right-side').height(mainHeight - colPaddingTop);

function stickyNav() {
    function stickyLogic() {
        var scrollTop = $(window).scrollTop();

        if (scrollTop > 1) {
            $header.addClass('sticky');
            $baseHeaders.css('opacity', 0);
        } else {
            $header.removeClass('sticky');
            $baseHeaders.css('opacity', 1);
        }
    }

    stickyLogic();
    $(window).on('scroll resize', function() {
        if (helpers.viewport().width > 1279) {
            stickyLogic();
        }
    });
}
stickyNav();
$('.news-slick').hide();

function newsSlick() {
    $('.news-slick').slick({
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: false,
        speed: 300
    });
}

$('.news-images').on('click', '.news-image', function() {
    $('.news-images').hide();
    $('.news-slick').show();
    newsSlick();
});

$('.services-section').on('click', '> li', function() {
    $('.services-section li').removeClass('active');
    $(this).addClass('active');
});